<template>
    <div class="processInfoItem">
        <div class="header">
            工单号：{{processInfo.no}}
        </div>
        <div class="time">发起时间： {{processInfo.createTime}}</div>
        <div style="height: 20px;line-height: 20px;color: #00ffff;font-size: 12px;">类型： {{typeName}}</div>
        <div v-for="(item,index) in processInfo.equipmentList" :key="index">
            <div v-if="item.type === 'flow_emergency_repair'"
                 style="height: 20px;line-height: 20px;color: #ffff00;font-size: 14px;">
                {{item.equipmentName || '暂无设备名'}}/{{item.equipmentNo || '暂无设备编号'}}/{{item.flawLevel || '暂无缺陷等级'}}
            </div>
            <div v-if="item.type === 'flow_overhaul' || item.type === 'flow_overhaul_1'"
                 style="height: 20px;line-height: 20px;color: #ffff00;font-size: 14px;">
                {{item.equipmentName || '暂无设备名'}}/{{item.equipmentNo || '暂无设备编号'}}
            </div>
        </div>
        <div class="footer" style="padding-top: 20px">
            <div><span class="title">当前环节：</span> <span class="value">{{processInfo.taskName}}</span></div>
        </div>
        <div class="footer">
            <div><span class="title">处理人：</span> <span  class="value">{{processInfo.handleName}}</span></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProcessInfoItem',
    props: {
        processInfo: {
            type: Object
        }
    },
    computed: {
        typeName() {
            // 10=新装, 20=增容, 30=减容, 40=批量新装, 50=分户, 60=并户
            switch (this.processInfo.type) {
                case 'flow_emergency_repair':
                    return '抢修';
                case 'flow_overhaul':
                    return '计划检修';
                case 'flow_overhaul_1':
                    return '非计划检修';
                default:
                    return '';
            }
        },
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.processInfoItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 140px;
    border: 2px solid @blue;
    padding: 10px 10px;
    margin: 15px;
    //background-color: rgba(12, 149, 149, 0.2);
    background-color: rgba(0, 250, 247, 0.2);

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
    }

    .time {
        font-size: 12px;
        color: @blue
    }

    .content {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .contentItem {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .value {
                height: 20px;
                line-height: 20px;
                color: @yellow;
                font-size: 14px;
            }
            .title {
                height: 20px;
                line-height: 20px;
                color: @cyan;
                font-size: 12px;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        .title {
            height: 20px;
            line-height: 20px;
            color: @cyan;
            font-size: 12px;
        }
        .value {
            height: 20px;
            line-height: 20px;
            color: @green;
            font-size: 13px;
        }
    }

}

</style>
