<!--业务跟踪-->
<template>
    <widget-framework v-if="dataLoaded" title="业务跟踪" :show-border="false">
        <template #content>
            <div class="businessTracking">
                <div class="items">
                    <process-info-item
                        v-for="(item, index) in processInfos"
                        :processInfo="item"
                        :key="index"
                    ></process-info-item>
                </div>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import ProcessInfoItem from './BusinessProcessInfo/ProcessInfoItem';

export default {
    name: 'BusinessTracking',
    components: {
        WidgetFramework,
        ProcessInfoItem
    },
    data() {
        return {
            dataLoaded: false,
            processInfos: [],
            params: {
                processDefinitionKey: 'flow_business_apply',
                size: 3,
                loadLevel: '',
                startTime: '',
                endTime: '',
            },
            keyValues: {
                loadLevel: []
            }
        };
    },
    methods: {
        paramChange() {
            this.getBusinessApplyProgress();
        },
        getBusinessApplyProgress() {
            this.$client.queryBusinessTrackingVOList().then((ret) => {
                this.processInfos = ret.data || [];
                this.dataLoaded = true;
            });
        },
        async bindKeyValues() {
            const dictMap = [
                {parentCode: 'load_level', listName: 'loadLevel'}
            ];
            this.$client.getDicDataArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this.keyValues[dict.listName] = data;
                }
            });
        },
        async initFormValue() {
            await this.bindKeyValues();
        },
    },
    mounted() {
        this.initFormValue();
        this.getBusinessApplyProgress();
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.businessTracking {
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column;

    .queryParams {
        display: flex;
        justify-content: flex-start;
        padding: 0 10px;

        & > div{
            margin: 0 5px;
        }

        /deep/ .el-input__inner {
            border: 1px solid @lightBlue;
            background-color: rgba(0, 204, 255, 0.2);
            color: @cyan;

            input {
                background-color: unset;
                color: @cyan;
            }
        }
    }

    .items {
        display: flex;
        flex-flow: row wrap;
    }
}
</style>
